
import $ from 'jquery';
window.$ = window.jQuery = $;

const primaryCollectionField = require('./admin/primaryCollectionField');

$(function() {
    primaryCollectionField.init();
});

$(document).on('click', '.modalToggle', function (e) {
    Livewire.emit('toggleModal', $(this).attr('data-modal-target'));
});

$(function () {

    const $tableFilterInputs = $('input.live-search');
    if ($tableFilterInputs.length) {
        $tableFilterInputs.each(function(i, input) {

            const $table = $(this).closest('table');
            const $rows = $table.find('tbody tr');
            const $input = $(input);

            $input.on('keyup', function() {

                const query = $input.val().toLowerCase();

                if (query.length < 3) {
                    $rows.show();
                } else {
                    $rows.each(function(i, row) {

                        if ($(row).html().toLowerCase().indexOf(query) > -1) {
                            $(row).show();
                        } else {
                            $(row).hide();
                        }

                    });
                }
            });
        });
    }
});

// Landing page builder
var landingPageBuilder = require('./admin-components/landingPageBuilder');
$(function() {
    landingPageBuilder.init();
});